.container {
  margin: 10px;
}
.activities-table {
border-collapse: collapse;
text-align: left;
width: 100%;
}

.activities-table  tbody tr:hover {
  color: #212529;
  background-color: rgba(0,0,0,.075);
  cursor: pointer;
}

.activities-table th {
  background-color: #dde0b7;
  border-bottom: 2px solid #1b1e217d;
  padding: 0.5rem;
}

.activities-table td {
  padding: 0.65rem;
}

.activities-table thead th {
  text-align: left;
  border-top: 0px;
}

tr:nth-child(2n + 1) {
  background-color: #f2f2f2;
 }

 
tr {
  width: 100%;
}


th:nth-child(3), td:nth-child(3) {
padding-right: 15px;
padding-left: 0px;
}

th:nth-child(2), th:nth-child(4), td:nth-child(2), td:nth-child(4) {
  padding-left: 0px;
}

.activity {
  line-height: 1.5;
}
.line-wrap {
  white-space: pre-wrap;
}